import { IonItem, IonPopover, useIonAlert } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { BiUserCircle } from "react-icons/bi";
import { BsFillPencilFill, BsTrashFill } from "react-icons/bs";
import { FaEllipsisV } from "react-icons/fa"
import { MdPersonRemoveAlt1 } from "react-icons/md";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { User } from "../../../Common/Interfaces/Entities";
import Confirmation, { ConfirmationInterface } from "../../../components/Confirmation/Confirmation";
import { AppDispatch } from "../../../Redux/Store";
import { UserActions } from "../../../Redux/User/Actions";
import { UserState } from "../../../Redux/User/Interface";
import Loading from "../../../components/Loading";
import Translate from "../../../components/Translate";


const UserCard: React.FC<{ user: User, userReducer: UserState }> = ({ user, userReducer }) => {
    const dispatch = useDispatch<AppDispatch>()
    const [popover, togglePopover] = useState<{ open: boolean, event: Event | undefined }>({ open: false, event: undefined })
    const [submitted, setSubmitted] = useState<boolean>(false)
    const [redirect, setRedirect] = useState<string>("")
    const [infoAlert] = useIonAlert()
    const [showAlert, setShowAlert] = useState<ConfirmationInterface>({ open: false, function: null, message: "" })

    useEffect(() => {
        if (submitted === false) return
        setSubmitted(false)
        togglePopover({ open: false, event: undefined })
        if (userReducer.updateUserStatus === "fulfilled") infoAlert({
            header: 'Success.', buttons: ['OK'],
            message: userReducer.updateUserMessage,
        })
        if (userReducer.updateUserStatus === "rejected") infoAlert({
            header: 'Error.', buttons: ['OK'],
            message: userReducer.updateUserMessage || "Unexpected error occurred.",
        })
    }, [userReducer.updateUserStatus, infoAlert, submitted, userReducer.updateUserMessage])


    const handleRedirect = id => {
        togglePopover({ open: false, event: undefined })
        setTimeout(() => setRedirect("/users/edit/" + id), 100)
    }

    const handleKick = () => {
        const num_admins = userReducer.users.filter(u => u !== undefined && u !== null && u.type_id === 3 && u.organization_id === user.organization_id && user.archived !== 1)
        if (num_admins.length === 1 && user.type_id >= 3) return infoAlert({
            header: 'Restricted.', buttons: ['OK'],
            message: "You cannot remove the last organization admin. Please create a new organization admin before removing this user from the organization.",
        })
        setSubmitted(true)
        const data = new FormData()
        data.append("id", user.id.toString())
        data.append("organization_id", null)
        dispatch(UserActions.updateUser({ user: data }))
    }

    const handleDelete = () => {
        const num_admins = userReducer.users.filter(u => u !== undefined && u !== null && u.type_id === 3 && u.organization_id === user.organization_id && user.archived !== 1)
        if (num_admins.length === 1 && user.type_id >= 3) return infoAlert({
            header: 'Restricted.', buttons: ['OK'],
            message: "You cannot delete the last organization admin. Please create a new organization admin before deleting this user.",
        })
        setSubmitted(true)
        const data = new FormData()
        data.append("id", user.id.toString())
        data.append("archived", "1")
        dispatch(UserActions.updateUser({ user: data }))
    }

    if (redirect !== "") return <Redirect to={{
        pathname: redirect,
        state: { prevLocation: "/organization/" + user.organization_id }
    }} />

    return (
        <div className="grid grid-cols-12 border-solid border-b-2 border-slate-100 py-2 mb-2">
            <Confirmation setShowAlert={setShowAlert} showAlert={showAlert} />
            <Loading isOpen={userReducer.updateUserStatus === "pending"} message="Updating user... Please wait." />
            <BiUserCircle className="col-span-1 w-8 h-10 text-slate-800" />

            <span className="col-span-10 ml-3">
                <p className="text-base font-bold text-slate-600"> {(user.username)}</p>
                <p className="text-sm text-slate-600 -mt-1"> {user.email} ({userReducer.userTypes.types[user.type_id]?.name})</p>
            </span>

            <span className="col-span-1 flex items-center justify-end text-slate-800 cursor-pointer">
                {userReducer.current.type_id >= user.type_id ? <FaEllipsisV onClick={e => togglePopover({ open: true, event: e.nativeEvent })} /> : null}
            </span>

            {userReducer.current.type_id >= user.type_id ? <IonPopover
                onDidDismiss={e => togglePopover({ open: false, event: undefined })}
                isOpen={popover.open} event={popover.event}
                size="auto" side="bottom" alignment="center">

                {userReducer.current.type_id === 4 ? <IonItem className="text-red-500 cursor-pointer hover:text-red-600" detail={false} onClick={e => {
                    setShowAlert({
                        open: true,
                        function: handleDelete,
                        message: "Are you sure you want to delete this user?"
                    })
                }}>
                    <BsTrashFill className="mr-3" />
                    <p>Delete</p>
                </IonItem> : null}

                <IonItem className="text-blue-500 cursor-pointer hover:text-blue-600" detail={false} onClick={e => {
                    setShowAlert({
                        open: true,
                        function: handleKick,
                        message: "Are you sure you want to remove this user from your organization?"
                    })
                }}>
                    <MdPersonRemoveAlt1 className="mr-3" />
                    <p><Translate label="Leave Organization" /></p>
                </IonItem>

                <IonItem className="text-cythero-primary cursor-pointer hover:text-cythero-primary-dark" detail={false}>
                    <BsFillPencilFill className="mr-3" />
                    <p onClick={e => handleRedirect(user.id)}><Translate label="Edit User" /></p>
                </IonItem>

            </IonPopover> : null}
        </div>
    )
}



export default UserCard